<template>
  <div>
    <template v-if="loading">
      <v-overlay>
        <v-alert v-if="offline" type="error">
          Please check your internet connection,
          <v-btn text @click="fetchData">Reload</v-btn>
        </v-alert>
        <v-progress-circular v-else indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </template>
    <v-card
      :img="$vuetify.breakpoint.xs ? logoXs : undefined"
      v-if="!loading"
      height="90vh"
      flat
      color="#fafafa"
    >
      <v-container style="height:100%">
        <v-row style="height:90%" align="center">
          <v-col cols="9" md="6">
            <div @dblclick="toggleEditMode('title')">
              <h1
                class="font-weight-black text-uppercase primary--text"
                :class="{
                  headline: $vuetify.breakpoint.xs,
                  'display-2': $vuetify.breakpoint.smAndUp
                }"
                v-html="hero.title"
              ></h1>
            </div>

            <v-dialog :value="editMode == 'title'" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Update</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea v-model="hero.title" name="title" label="Title"></v-textarea>
                        <span>
                          Use
                          <code>&lt;br&gt;</code>for a line break
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editMode = ''">Close</v-btn>
                  <v-btn color="blue darken-1" text @click="updateHero(hero)">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <div @dblclick="toggleEditMode('description')">
              <p class="py-3" v-html="hero.description"></p>
            </div>

            <v-dialog :value="editMode == 'description'" persistent max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">Update</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="hero.description"
                          name="description"
                          label="Description"
                          id="id"
                        ></v-textarea>
                        <span>
                          Use
                          <code>&lt;br&gt;</code>for a line break
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editMode = ''">Close</v-btn>
                  <v-btn color="blue darken-1" text @click="updateHero(hero)">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
              class="ma-0"
              href="/catalogue_web.pdf"
              :large="$vuetify.breakpoint.smAndUp"
              color="primary"
            >Get Cataloge</v-btn>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.smAndUp" align-self="stretch" cols="3" md="6">
            <v-img contain :src="logoSmUp"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Hero",
  data() {
    return {
      editMode: "",
      loading: false,
      offline: false,
      logoXs: require("@/assets/clients/media/banner/logo-background.png"),
      logoSmUp: require("@/assets/clients/media/banner/3d-rigcare-logo.png")
    };
  },
  computed: {
    ...mapGetters(["currentUserRole", "getHero"]),
    hero() {
      return this.getHero.hero;
    }
  },
  methods: {
    ...mapActions(["update", "bind"]),

    updateHero(data) {
      this.update({
        collection: "pages",
        doc: "home",
        data: {
          hero: {
            title: data.title,
            description: data.description
          }
        }
      })
        .then(response => {
          this.editMode = "";
          // console.log("Res" + response.message);
          this.bind({
            collection: "pages",
            doc: "home",
            data: "hero"
          });
        })
        .catch(error => {
          // console.log("Err" + error);
        });
    },
    toggleEditMode(e) {
      if (this.currentUserRole == "admin") {
        this.editMode = e;
      }
    },
    fetchData() {
      if (this.getHero == null) {
        this.loading = true;
        this.bind({
          collection: "pages",
          doc: "home",
          data: "hero"
        })
          .then(response => {
            this.loading = false;
          })
          .catch(error => {
            this.offline = true;
          });
      }
    }
  },
  created() {
    this.fetchData();
  }
};
</script>
